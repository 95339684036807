import { createSlice } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import type { RootState } from "../store";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SCHED_DISP_TYPES } from "../../shared/globalConstants";

interface CalendarState {
  activeDate: Date;
  selectedMonthIndex: number;
  selectedYear: number;
  displayMode: string;
  landingPageDisplayMode: string;
  isAddEvent: boolean;
  selectedDate: Dayjs;
  selectedStaffId: number | null;
}

const todaysDate = new Date();
const todaysYear = todaysDate.getFullYear();
const todaysMonth = todaysDate.getMonth();
const todaysDay = todaysDate.getDate();

const initialState: CalendarState = {
  activeDate: new Date(todaysYear, todaysMonth, todaysDay),
  selectedMonthIndex: todaysDate.getMonth(),
  selectedYear: todaysDate.getFullYear(),
  displayMode: SCHED_DISP_TYPES[1],
  landingPageDisplayMode: SCHED_DISP_TYPES[1],
  isAddEvent: false,
  selectedDate: dayjs(todaysDate),
  selectedStaffId: null,
};

interface AddEventPayload {
  selectedDatetime: Dayjs;
  selectedStaffId: number | null;
}

const calendarSlice = createSlice({
  name: "calendar",
  initialState: initialState,
  reducers: {
    // add your non-async reducers here
    selectNewDay: (state, action: PayloadAction<Date>) => {
      state.activeDate = action.payload;
      state.selectedMonthIndex = action.payload.getMonth();
      state.selectedYear = action.payload.getFullYear();
    },
    changeMonth: (state, action: PayloadAction<Date>) => {
      state.selectedMonthIndex = action.payload.getMonth();
      state.selectedYear = action.payload.getFullYear();
    },
    changeYear: (state, action: PayloadAction<number>) => {
      state.selectedYear = action.payload;
    },
    changeDisplayMode: (state, action: PayloadAction<string>) => {
      state.displayMode = action.payload;
    },
    changeLandingPageDisplayMode: (state, action: PayloadAction<string>) => {
      state.landingPageDisplayMode = action.payload;
    },
    addEvent: (state, action: PayloadAction<AddEventPayload>) => {
      state.isAddEvent = true;
      state.selectedDate = action.payload.selectedDatetime;
      state.selectedStaffId = action.payload.selectedStaffId;
    },
    closeAddEvent: (state) => {
      state.isAddEvent = false;
      state.selectedStaffId = null;
    },
  },
  //extraReducers: {
  // add your async reducers here
  //}
});

// Action creators
export const {
  selectNewDay,
  changeMonth,
  changeYear,
  changeDisplayMode,
  changeLandingPageDisplayMode,
  addEvent,
  closeAddEvent,
} = calendarSlice.actions;
export const selectDisplayMode = (state: RootState) =>
  state.calendar.displayMode;

export const selectLandingPageDisplayMode = (state: RootState) =>
  state.calendar.landingPageDisplayMode;
export const selectIsAddEvent = (state: RootState) => state.calendar.isAddEvent;
export const selectActiveDate = (state: RootState) => state.calendar.activeDate;
export const selectSelectedDate = (state: RootState) =>
  state.calendar.selectedDate;
export default calendarSlice.reducer;
